import * as React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import HomeIntro from "../components/home-intro";
import parse from "html-react-parser";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

const BlogIndex = ({data, location}) => {
    const siteTitle = data.site.siteMetadata?.title || `Nienk`;
    const posts = data.allMarkdownRemark.nodes;

    if (posts.length === 0) {
        return (
            <Layout location={location} title={siteTitle}>
                <Seo title="Home"/>
                <HomeIntro/>
                <section id="homepage-posts" className="container-lg">
                    <div className="row justify-content-center">
                        <div className="col-md-10 post-container text-center">
                            <p className="lead">
                                Er zijn nu nog geen berichten... Kom snel terug of volg mij op <a
                                href="https://www.instagram.com/nienknl" target="_blank" rel="noreferrer">Instagram</a>
                            </p>
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }

    return (
        <Layout title={siteTitle} isHomePage>
            <Seo title="Home"/>
            <HomeIntro/>
            <section id="homepage-posts" className="container-lg">
                <div className="row justify-content-center">
                    <div className="col-md-10 post-container">
                        {posts.map((post, index) => {
                            const title = post.frontmatter.title || post.fields.slug
                            return (
                                <article key={post.frontmatter.date + post.fields.slug}>
                                    <div className="post-card mb-5 pb-5">
                                        <div className="row justify-content-center">
                                            {post.frontmatter.featuredImage && (
                                                <div className="post-image">
                                                    {/*<Link to={post.uri}>*/}
                                                    <GatsbyImage
                                                        image={getImage(post.frontmatter.featuredImage)}
                                                        alt={title}
                                                    />
                                                    {/*</Link>*/}
                                                </div>
                                            )}
                                            <div className="col-10 post-body">
                                                <div className="d-flex justify-content-end align-content-start">
                                                    <div
                                                        className="label label-blue label-big label-negative">{post.frontmatter.formattedDate}</div>
                                                </div>
                                                <div className="post-header">
                                                    {/*<Link to={post.uri}>*/}
                                                    <h1 className="post-title">{title}</h1>
                                                    {/*</Link>*/}
                                                </div>
                                                {parse(post.html)}
                                                <div className="post-footer d-flex justify-content-end mt-5">
                                                    {post.frontmatter.categories.map(category => (
                                                        <div className="label label-pink label-small"
                                                             key={category}>{category}</div>
                                                    ))}
                                                    {/*<Link to={post.uri}>*/}
                                                    {/*    <div>Lees meer →</div>*/}
                                                    {/*</Link>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            )
                        })}
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
        filter: {frontmatter: {status: {eq: "published"}}}
        sort: { fields: [frontmatter___date], order: DESC }
        ) {
      nodes {
        excerpt
        html
        fields {
          slug
        }
        frontmatter {
          featuredImage {
            childImageSharp {
                gatsbyImageData(
                    width: 1320
                    placeholder: BLURRED
                    layout: CONSTRAINED
                )
            }
          }
          formattedDate: date(formatString: "DD/MM/YYYY")
          date
          title
          description
          categories
          tags
          type
        }
      }
    }
  }
`;
