import React from "react";
import TitleImgLarge from "../assets/images/Title_Background.png";
import TitleImgSmall from "../assets/images/Title_img-swirl.png";
import {graphql, useStaticQuery} from "gatsby";

const HomeIntro = () => {
    const {page} = useStaticQuery(graphql`
    query HomePageQuery {
      page: site {
        siteMetadata {
          title
          intro
          introTitle
        }
      }
    }
  `)


    return (
        <section id="home-intro" className="container-lg mb-4">
            <div className="row justify-content-center d-none d-lg-flex">
                <div className="col-lg-12 col-xl-10 col-xxl-9 position-relative intro-container">
                    <img src={TitleImgLarge} alt="Nienk illustratie" className="position-absolute"/>
                    <div className="row justify-content-start align-self-center">
                        <div className="offset-2 col-md-5 text-center intro-text">
                            <h2 className="display">{page.siteMetadata.introTitle}</h2>
                            {page.siteMetadata.intro}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center d-flex d-lg-none">
                <div className="col-6 col-sm-4 order-sm-last">
                    <img src={TitleImgSmall} alt="Nienk illustratie"/>
                </div>
                <div className="col-10 col-sm-6 text-center text-sm-start intro-text align-self-center">
                    <h2 className="display">{page.siteMetadata.introTitle}</h2>
                    {page.siteMetadata.intro}
                </div>
            </div>
        </section>
    );
};

export default HomeIntro;
